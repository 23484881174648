<template>
  <docs-layout class="space-y-4">
    <h1 class="text-3xl font-bold">Variants</h1>
    <p class="space-x-1">
      <asom-badge v-for="(v, i) in variants" :key="i" :variant="v">
        {{v}}
      </asom-badge>
    </p>
    
    <h1 class="text-3xl font-bold">With icon</h1>
    <p>
      <asom-badge class="space-x-1">
        Close
        <asom-icon icon="x" size="sm" />
      </asom-badge>
    </p>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'

export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      variants: [ 'primary', 'secondary', 'success', 'error', 'warning', 'info', 'light', 'dark' ]
    }
  }
}
</script>